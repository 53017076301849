@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Roboto+Mono&family=Vazirmatn&display=swap");
:root {
    --font: "Comfortaa", "Roboto Mono", "Vazirmatn";
}

.progress {
    position: fixed;
    top: 0;
    height: 3px;
    background-color: rgb(201, 134, 205);
}

.logo {
    position: fixed;
    left: 0;
    top: 0;
    width: 5em;
}
.mainText {
    font-family: var(--font);
    font-size: 1.5em;
    font-variant: no-common-ligatures;
    line-height: 1.2em;
    font-weight: 400;
    width: 75%;
    min-height: fit-content;
    text-align: justify;
    max-lines: 2;
    padding-bottom: 0;
    border-radius: 7px;
}

.mainInput {
    width: 250px;
    height: 3rem;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(255, 250, 250, 0.099);
    font-size: 1.5em;
    color: rgba(230, 230, 250, 0.631);
    text-align: center;
    padding-block: 0.5em;
    outline: none;
}

.accuracyTitle,
.wpmTitle {
    font-size: 2rem;
    line-height: 1.5rem;
}

.accuracy,
.wpm {
    font-size: 4rem;
    line-height: 4rem;
    font-family: var(--font);
    font-weight: 400;
}

.actionButtons {
    position: fixed;
    bottom: 2em;
    display: flex;
    gap: 2rem;
    background-color: #171a1f56;
    padding: 1em;
    border-radius: 7px;
    min-width: 20vw;
    min-height: 10vh;
}

.restartButton {
    margin-top: 1rem;
    cursor: pointer;
    padding-top: 0.5em;
    border-radius: 15px;
    padding-inline: 0.1em;
}

.restartButton p {
    margin-top: -0.5rem;
    font-size: 80%;
}

.restartButton:hover,
.restartButton:focus {
    background-color: #6b6f7843;
    transition: all 0.2s;
    outline: none;
}

.wordCountButtons {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.wordCountContainer p {
    margin-bottom: 0.2em;
}

.wordCountButtons button {
    border: none;
    font-size: 50%;
    height: fit-content;
    font-family: var(--font);
    padding: 0.5rem;
    background-color: rgb(213, 231, 231);
    cursor: pointer;
}

.userNameHeader {
    color: rgb(60, 121, 144);
}

.footer {
    background-color: #171a1f;
    height: 10%;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nameInput {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid black;
    margin-right: 1rem;
    margin-top: 25vh;
    text-align: center;
    font-size: 4rem;
    font-family: var(--font);
    font-weight: 500;
    outline: none;
    color: rgb(28, 108, 144);
}

.connectButton {
    background-color: rgb(28, 108, 144);
    border: none;
    border-radius: 7px;
    /* width: 10em; */
    height: 3em;
    padding: 1em;
    font-size: 2rem;
    font-family: var(--font);
    cursor: pointer;
}

.leaderBoardTable {
    border-collapse: collapse;
}

.leaderBoardTable thead > tr > th,
.leaderBoardTable > tbody > tr > td {
    border: 1px solid black;
    border-collapse: separate;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
}

.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    max-height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
